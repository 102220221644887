import { getSettingValue } from '@shared/helpers/settings';
import { CONFIG } from '@shared/environment/environment';
import { INSURER_CONFIG } from '@shared/insurer';
export default (function (context, inject) {
    async function goBackHome(saveStep, customerAreaUrlParam) {
        var _a, _b, _c, _d;
        const store = context.store;
        const { environment } = CONFIG;
        let settingName = 'customerAreaUrl';
        // goToLocalePath permet de rediriger vers la page d'accueil du selfcare au lieu d'utiliser l'url de l'assureur
        const byPassCustomerAreaUrl = (_b = (_a = INSURER_CONFIG.declaration.closeCallBackBtnBehavior) === null || _a === void 0 ? void 0 : _a.goToLocalePath) === null || _b === void 0 ? void 0 : _b.some((env) => env === environment);
        if (saveStep) {
            const activeStep = typeof saveStep === 'string' ? saveStep : store.state.pages.activeStep;
            if (activeStep)
                await store.dispatch('claim/updateAndSaveClaimStep', activeStep);
        }
        if (store.getters['authentication/isAuthenticatedWithMagicLink']) {
            store.commit('authentication/SET_MAGIC_LINK', null);
            // appeler une url spécifique stockée dans les settings, si le selfcare est appelé via un magicLink
            if ((_d = (_c = INSURER_CONFIG.declaration.closeCallBackBtnBehavior) === null || _c === void 0 ? void 0 : _c.specificTargetIfMagicLink) === null || _d === void 0 ? void 0 : _d.some((env) => env === environment)) {
                settingName = 'devCustomerAreaUrl';
            }
        }
        if (store.state.settings.appSettings &&
            store.state.settings.appSettings.some((appSetting) => appSetting.name === settingName) &&
            getSettingValue(settingName, {
                settings: store.state.settings.appSettings,
            }) !== null &&
            !byPassCustomerAreaUrl) {
            window.location.href =
                getSettingValue(settingName, {
                    settings: store.state.settings.appSettings,
                }) + (customerAreaUrlParam ? '/' + customerAreaUrlParam : '');
        }
        else {
            context.$goToLocalePath('index');
        }
    }
    inject('goBackHome', goBackHome);
});
